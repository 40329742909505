<template>
  <div class="main-page">
      <div class="banner">
        <div class="banner-content sc-width">
          <div class="banner-left">
            BuyerSpace:
            <div class="color1">
              Making Appointment with <div class="color">
              <img src="https://connexima.s3-us-west-1.amazonaws.com/documents/4BJGLsEpnAzTcBzFoHPQ8j.png"
                   alt=""/>
            </div>
          </div>
<!--            <div class="banner-left-bottom">-->
<!--              Explore Dates, Locations, & Participating Companies<br/> at Top Trade Shows-->
<!--            </div>-->
            <div class="banner-btns">
              <div class="banner-btn create-btn" @click="createCompany()">
                <template v-if="!hasStore">Virtual Attendee</template>
                <template v-else>My Profile</template>
              </div>
              <div class="banner-btn view-btn" @click="viewCompany()">
                <template v-if="showType == 1">
                  View Companies
                </template>
                <template v-else>
                  View Trade Shows
                </template>

              </div>
            </div>
          </div>
          <div class="banner-right">
            <div class="research-radar-wrapper"><!--qv q:id=4n q:key=OgE1:3b_0-->
              <div class="research-radar" q:key="kJ_3"><!--qv q:id=4o q:key=pd7A:kJ_0-->
                <img height="1" width="1" alt=""
                     src="https://connexima.s3-us-west-1.amazonaws.com/documents/iSfWE3PmM2rqCbyjcyacsq.png"
                     class="lazy-image lazy-image-loaded research-radar-background"
                     on:load="q-9e06af5e.js#s_Deti9nTnz3Q[0]"
                     on:qvisible="q-9e06af5e.js#_hW[1]"
                     q:key="BO_0" q:id="4p"><!--/qv-->
                <!--qv q:id=4q q:key=pd7A:kJ_1--><img height="1" width="1" alt="" src="@/assets/trade-show/logo.png"
                                                      class="lazy-image lazy-image-loaded research-radar-logo"
                                                      on:load="q-9e06af5e.js#s_Deti9nTnz3Q[0]"
                                                      on:qvisible="q-9e06af5e.js#_hW[1]" q:key="BO_0" q:id="4r"><!--/qv-->
                <!--qv q:id=4s q:key=rLUX:kJ_2--><!--qv q:key=hp_0-->
                <div class="research-radar-scanner-wrapper research-radar-scanner-wrapper-visible"
                     on:qvisible="q-9622af32.js#_hW[0]" q:id="4t">
                  <div class="research-radar-scanner"></div>
                  <div class="research-radar-items"><!--qv q:id=4u q:key=cww2:0-->
                    <div style="--index:1" class="research-radar-item" q:key="o6_0" q:id="4v" :class="{ 'research-radar-item-active': activeIndex === 1 }" >
                      <div style="--index:6" q:key="0"></div>
                      <div style="--index:5" q:key="1"></div>
                      <div style="--index:4" q:key="2"></div>
                      <div style="--index:3" q:key="3"></div>
                      <div style="--index:2" q:key="4"></div>
                      <div style="--index:1" q:key="5"></div>
                    </div><!--/qv--><!--qv q:id=4w q:key=cww2:1-->
                    <div style="--index:2" class="research-radar-item" q:key="o6_0" q:id="4x" :class="{ 'research-radar-item-active': activeIndex === 2 }">
                      <div style="--index:7" q:key="0"></div>
                      <div style="--index:6" q:key="1"></div>
                      <div style="--index:5" q:key="2"></div>
                      <div style="--index:4" q:key="3"></div>
                      <div style="--index:3" q:key="4"></div>
                      <div style="--index:2" q:key="5"></div>
                    </div><!--/qv--><!--qv q:id=4y q:key=cww2:2-->
                    <div style="--index:3" class="research-radar-item" q:key="o6_0" q:id="4z" :class="{ 'research-radar-item-active': activeIndex === 3 }">
                      <div style="--index:8" q:key="0"></div>
                      <div style="--index:7" q:key="1"></div>
                      <div style="--index:6" q:key="2"></div>
                      <div style="--index:5" q:key="3"></div>
                      <div style="--index:4" q:key="4"></div>
                      <div style="--index:3" q:key="5"></div>
                    </div><!--/qv--><!--qv q:id=50 q:key=cww2:3-->
                    <div style="--index:4" class="research-radar-item" q:key="o6_0" q:id="51" :class="{ 'research-radar-item-active': activeIndex === 4 }">
                      <div style="--index:9" q:key="0"></div>
                      <div style="--index:8" q:key="1"></div>
                      <div style="--index:7" q:key="2"></div>
                      <div style="--index:6" q:key="3"></div>
                      <div style="--index:5" q:key="4"></div>
                      <div style="--index:4" q:key="5"></div>
                    </div><!--/qv--><!--qv q:id=52 q:key=cww2:4-->
                    <div style="--index:5" class="research-radar-item" q:key="o6_0" q:id="53" :class="{ 'research-radar-item-active': activeIndex === 5 }">
                      <div style="--index:10" q:key="0"></div>
                      <div style="--index:9" q:key="1"></div>
                      <div style="--index:8" q:key="2"></div>
                      <div style="--index:7" q:key="3"></div>
                      <div style="--index:6" q:key="4"></div>
                      <div style="--index:5" q:key="5"></div>
                    </div><!--/qv--><!--qv q:id=54 q:key=cww2:5-->
                    <div style="--index:6" class="research-radar-item" q:key="o6_0" q:id="55" :class="{ 'research-radar-item-active': activeIndex === 6 }">
                      <div style="--index:11" q:key="0"></div>
                      <div style="--index:10" q:key="1"></div>
                      <div style="--index:9" q:key="2"></div>
                      <div style="--index:8" q:key="3"></div>
                      <div style="--index:7" q:key="4"></div>
                      <div style="--index:6" q:key="5"></div>
                    </div><!--/qv--><!--qv q:id=56 q:key=cww2:6-->
                    <div style="--index:7" class="research-radar-item" q:key="o6_0" q:id="57" :class="{ 'research-radar-item-active': activeIndex === 7 }">
                      <div style="--index:12" q:key="0"></div>
                      <div style="--index:11" q:key="1"></div>
                      <div style="--index:10" q:key="2"></div>
                      <div style="--index:9" q:key="3"></div>
                      <div style="--index:8" q:key="4"></div>
                      <div style="--index:7" q:key="5"></div>
                    </div><!--/qv--></div>
                </div><!--/qv--><!--/qv-->
                <div class="research-radar-fader"></div>
              </div><!--/qv--></div>
<!--            <div class="swiper-container swiper">-->
<!--              <div class="swiper-wrapper companies-swiper">-->
<!--                <div class="imgshows swiper-slide companies-content" v-for="(item,index) in swiperCompanies" :key="index" @click="showCompanies()">-->
<!--                  <div class="item" >-->
<!--                    <div class="item-wrap">-->
<!--                      <div class="item-mid">-->
<!--                        <div class="item-logo">-->
<!--                          <img :src="item.logo ? item.logo.url : ''" alt=""/>-->
<!--                        </div>-->
<!--                        <div class="item-right">-->
<!--                          <div class="item-name">{{item.company_name}}</div>-->
<!--&lt;!&ndash;                          <div class="item-location">{{item.country}}</div>&ndash;&gt;-->
<!--                        </div>-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="swiper-container swiper1">-->
<!--              <div class="swiper-wrapper companies-swiper">-->
<!--                <div class="imgshows swiper-slide companies-content" v-for="(item,index) in swiper1Companies" :key="index" @click="showCompanies()">-->
<!--                  <div class="item" >-->
<!--                    <div class="item-wrap">-->
<!--                      <div class="item-mid">-->
<!--                        <div class="item-logo">-->
<!--                          <img :src="item.logo ? item.logo.url : ''" alt=""/>-->
<!--                        </div>-->
<!--                        <div class="item-right">-->
<!--                          <div class="item-name">{{item.company_name}}</div>-->
<!--                          &lt;!&ndash;                          <div class="item-location">{{item.country}}</div>&ndash;&gt;-->
<!--                        </div>-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="swiper-container swiper2">-->
<!--              <div class="swiper-wrapper companies-swiper">-->
<!--                <div class="imgshows swiper-slide companies-content" v-for="(item,index) in swiper2Companies" :key="index" @click="showCompanies()">-->
<!--                  <div class="item" >-->
<!--                    <div class="item-wrap">-->
<!--                      <div class="item-mid">-->
<!--                        <div class="item-logo">-->
<!--                          <img :src="item.logo ? item.logo.url : ''" alt=""/>-->
<!--                        </div>-->
<!--                        <div class="item-right">-->
<!--                          <div class="item-name">{{item.company_name}}</div>-->
<!--                          &lt;!&ndash;                          <div class="item-location">{{item.country}}</div>&ndash;&gt;-->
<!--                        </div>-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>


      </div>
      <div class="main-content sc-width">
        <div class="intro-date">
          <div class="left-date">
            <div class="img">
              <a href="https://west.supplysideshow.com/en/home.html" target="_blank">
                <img src="https://connexima.s3-us-west-1.amazonaws.com/documents/p1s5dfoS3XFxGRTPceGYfF.png" alt=""/>
              </a>
            </div>
            <div class="left-w">
              <div class="date-time">
                <span><img src="@/assets/trade-show/date.png" alt=""/></span>
                OCT. 28 - OCT. 31, 2024 | EXPO HALL OCT. 30 & 31
              </div>
              <div class="date-address">(Mandalay Bay, Las Vegas)</div>
            </div>
          </div>
          <div class="right-date">
            <div class="right-btn" @click="companyList(adsShow,index)">
              Let Us Meet
            </div>
          </div>


        </div>
        <div class="search" :class="{'search-left': showType == 1,'search-left-2': showType > 1}">
          <div class="left-search">
            <div class="product" v-if="showType == 3">
              <span class="label label-product">Product Name:</span>
              <input type="text" class="product-name" v-model="filter.product_name"/>
            </div>
            <div class="product" v-if="showType == 2" style="display: flex">
              <div>
<!--                <span class="label label-product">Products/Services:</span>-->
                <input type="text" class="product-name" v-model="filter.product_name" placeholder="Products/Services"/>
              </div>
<!--              <div class="title">-->
<!--                <span class="label">Title:</span>-->
<!--                <input type="text" class="product-name" v-model="filter.title"/>-->
<!--              </div>-->
            </div>
            <template v-if="showType == 1">
              <div class="industry">
                <span class="label">Industry:</span>
                <select v-model="filter.industry">
                  <option value="0" >All</option>
                  <option :value="item" v-for="(item,index) in industryArr" :key="index">{{item}}</option>
                </select>
              </div>
<!--              <div class="date">-->
<!--                <span class="label label-product">Product Name:</span>-->
<!--                <input type="text" class="product-name" v-model="filter.product_name"/>-->
<!--&lt;!&ndash;                <input type="date" class="end-date" v-model="filter.end_date"/>&ndash;&gt;-->
<!--              </div>-->
            </template>


          <div class="btn" @click="searchShows()">
            Filter
          </div>
          </div>
          <div class="right-search">
            <select @change="changeType" v-model="showType">
              <option value="1">All Trade Shows</option>
              <option value="2">All Companies</option>
<!--              <option value="3">All Products</option>-->
            </select>
          </div>




        </div>
        <div class="all-data">
          <template v-if="loading && showType == 1" >
            <div class="loading-template">
              <img src="@/assets/loading.gif" />
            </div>
          </template>
          <template v-else>
            <div class="left-part" v-if="showType == 1">
              <div class="left-months" >
<!--                <div class="" :class="{'active' :currentMonth == 'all'}" @click="setCurrentMonth('all')">-->
<!--                  All-->
<!--                </div>-->
                <div v-for="month in months" :key="month" @click="setCurrentMonth(month)"
                :class="{'active' :currentMonth == month}">
                  {{ formatDate(month) }}
                </div>
              </div>
            </div>
            <div class="right-part" ref="dataList" @scroll="onMidScroll">
              <div class="data-item" v-for="(val, index) in processedItems " :key="index" v-if="showType == 1"
                   :class="[{ 'first-month': val.isFirstOfMonth,'not-current-month': currentMonth != 'all' && currentMonth != val.month }]"
                   :id="val.isFirstOfMonth? `first-${val.month}` : ''">
                <div class="item-top" @click="showDetail(val)">
                  <img :src="val.avatar.url" alt="" v-if="val.avatar"/>
                  <div class="item-top-content" v-else>{{val.name}}</div>
                </div>
                <div class="item-bottom">
                  <div class="bottom-name" :title="val.name">
                    {{val.name}}
                  </div>
                  <div class="bottom-item">
                    <div class="item-icon">
                      <img src="@/assets/trade-show/address.png" alt=""/>
                    </div>
                    <div class="item-label" :title="val.address">
                      {{val.address}}
                    </div>
                  </div>
                  <div class="bottom-item">
                    <div class="item-icon">
                      <img src="@/assets/trade-show/industry.png" alt=""/>
                    </div>
                    <div class="item-label" :title="val.industry">
                      {{val.industry}}
                    </div>
                  </div>
                  <div class="bottom-item bottom-item-date">
                    <div class="item-icon">
                      <img src="@/assets/trade-show/date.png" alt=""/>
                    </div>
                    <div class="item-label" v-if="!(val.start_date)">
                      {{changeDate(val.start_date)}}
                    </div>
                    <div class="item-label" v-else-if="!(val.end_date)">
                      {{changeDate(val.start_date)}}
                    </div>
                    <div class="item-label" v-else :title="changeDate(val.end_date)">

                      {{changeDateAndRemoveYear(val.start_date)}} - {{changeDate(val.end_date)}}

                    </div>
                  </div>
                  <div class="bottom-item">
                    <div class="item-icon" title="Exhibitors">
                      <img src="@/assets/trade-show/exhibitor.png" alt=""/>
                    </div>
                    <div class="item-label" title="Exhibitors">
                      {{val.exhibitor_num ? showExhibitor(val.exhibitor_num) : '--' }}
                    </div>
                  </div>
                  <div class="d-flex bottom-btn">
                    <div class="item-btn" @click="companyList(val,index)">
                      Let Us Meet ({{val.storefront_count}})
                    </div>
                    <div class="btn-check" >
                      <img src="@/assets/trade-show/check.png" alt="" v-if="val.storefront_attend_count > 0" title="You have already participated in this exhibition!" @click="batchCheck(index,0)"/>
                      <img src="@/assets/trade-show/not-check.png" alt="" class="not-check" v-else @click="batchCheck(index,1)" title="Attend The Show"/>

                    </div>
                  </div>

                  <!-- <div class="item-btn" @click="attendShow(val.id)" v-if="val.storefront_count == 0"> -->
                  <!-- Attend the Show
                </div>
                <div class="item-btn item-btn-company-list" @click="attendShow(val.id)" v-else>
                  View Company List
                </div> -->
                </div>

              </div>
            </div>


            <div class="companies" v-if="showType == 2 || showType == 3">



              <div class="companies-content sc-width">
                <div class="left-view">
                  <div class="view-item">
                    <div class="view-item-title"> By Purpose</div>
                    <div class="view-item-list">
                      <div class="view-item-list-item d-flex"  @click="changePurpose(null)"
                           :class="{'view-item-list-item-active':filter.purpose == null}">
                        <div class="view-list-icon"></div>
                        <div class="view-list-word">All</div>
                      </div>
                      <div class="view-item-list-item d-flex" @click="changePurpose('Sell Products')"
                           :class="{'view-item-list-item-active':filter.purpose == 'Sell Products'}">
                        <div class="view-list-icon"></div>
                        <div class="view-list-word" ><div>Sell Products</div></div>
                      </div>
                      <div class="view-item-list-item d-flex" @click="changePurpose('Buy Products')"
                           :class="{'view-item-list-item-active':filter.purpose == 'Buy Products'}">
                        <div class="view-list-icon"></div>
                        <div class="view-list-word"><div>Buy Products</div></div>
                      </div>
                    </div>
                  </div>
                  <div class="view-item">
                    <div class="view-item-title"> By Industry</div>
                    <div class="view-item-list">
                      <div class="view-item-list-item d-flex"  @click="changeIndustry(null)"
                           :class="{'view-item-list-item-active':filter.industry == null}">
                        <div class="view-list-icon"></div>
                        <div class="view-list-word">All</div>
                      </div>
                      <div class="view-item-list-item d-flex" @click="changeIndustry(item)" v-for="(item,index) in industry" :key="index"
                           :class="{'view-item-list-item-active':filter.industry == item}">
                        <div class="view-list-icon"></div>
                        <div class="view-list-word" :title="item"><div>{{item}}</div></div>
                      </div>
                    </div>
                  </div>
                  <div class="view-item-line"></div>
                  <div class="view-item">
                    <div class="view-item-title"> By Title</div>
                    <div class="view-item-list">
                      <div class="view-item-list-item d-flex"  @click="changeTitle(null)"
                           :class="{'view-item-list-item-active':filter.title == null}">
                        <div class="view-list-icon"></div>
                        <div class="view-list-word">All</div>
                      </div>
                      <div class="view-item-list-item d-flex" @click="changeTitle(item)" v-for="(item,index) in titleArr" :key="index"
                           :class="{'view-item-list-item-active':filter.title == item}">
                        <div class="view-list-icon"></div>
                        <div class="view-list-word" :title="item"><div>{{item}}</div> </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="loading && (showType == 2 || showType == 3)" >
                  <div class="loading-template-type-2">
                    <img src="@/assets/loading.gif" />
                  </div>
                </template>
                <div class="right-content" v-else>
                  <div class="maxdata" v-if="showMaxData">
                    <div class="help">
                      <div class="help-top d-flex">
                        <!--                  <div class="checkbox">-->
                        <!--                    <input type="checkbox" name="help" v-model="editInquiryForm.need_help"/>-->
                        <!--                  </div>-->
                        <div class="help-title">Let MaxData help you find customers</div>
                      </div>
                      <!--                <div class="help-desc">If you need this service, please check the option above</div>-->
                      <div class="help-contents d-flex">
                        <div class="help-item" v-if="" v-for="(helpItem,helpIndex) in helpData" :class="{'help-item-last':helpIndex == helpData.length-1}">
                          <div class="help-item-top">{{helpItem.doc_count}}</div>
                          <div class="help-item-line"></div>
                          <div class="help-item-name">
                            <template v-if="helpItem.key == '1'">US Importer</template>
                            <template v-if="helpItem.key == '2'">Foreign Exporter</template>
                            <template v-if="helpItem.key == '3'">Organic Factory</template>
                            <template v-if="helpItem.key == '4'">End-user Customer</template>
                            <template v-if="helpItem.key == '6'">Global Company</template>
                            <template v-if="helpItem.key == '7'">Non GMO</template>
                          </div>
                        </div>
                      </div>
                      <div class="help-btn" @click="jumpTo('/maxdata')">Start Search!</div>
                    </div>


                  </div>
                  <div class="main-companies">
                    <div class="item animatedBorder" @click="showCompanyShows(item)" v-if="tradeCompanies.length > 0"
                         v-for="(item,index) in tradeCompanies" :index="index">
                      <div class="item-wrap">
                        <div class="item-mid">
                          <div class="item-logo">
                            <img :src="item.logo.url" alt="" v-if="item.logo"/>
                            <img src="https://connexima.s3-us-west-1.amazonaws.com/documents/vxBpNJ7ctQgsU5xw8cH3GY.png" alt="" v-else/>
                          </div>
                          <div class="item-right">
                            <div class="item-name" :title="item.company_name">{{item.company_name}}</div>
                            <div class="item-location">{{item.country_name.name}}</div>
                          </div>

                        </div>
                        <div class="item-bottom">
                          <div class="bottom-item">
                            <div class="item-value item-value-sales-revenue" :title="item.sales_revenue">{{item.sales_revenue ? item.sales_revenue : '--' }}</div>
                            <div class="item-label">Annual Sales</div>

                          </div>
                          <div class="bottom-line"></div>
                          <div class="bottom-item">
                            <div class="item-value item-value-sales-revenue" :title="item.employees">{{item.employees ? item.employees : '--'}}</div>
                            <div class="item-label">Employees</div>

                          </div>
<!--                          <div class="bottom-line"></div>-->
<!--                          <div class="bottom-item">-->
<!--                            <div class="item-value" :title="item.company_type">{{item.company_type}}</div>-->
<!--                            <div class="item-label"> Type </div>-->
<!--                          </div>-->
                          <div class="bottom-line"></div>
                          <div class="bottom-item">
                            <div class="item-value">{{item.trade_shows.length}}</div>
                            <div class="item-label">Shows</div>

                          </div>
                        </div>
                        <div class="item-bottom item-bottom-2">
                          <div class="bottom-item">
                            <div class="item-value item-value-sales-revenue" :title="item.company_type">{{item.company_type ? item.company_type : '--' }}</div>
                            <div class="item-label">Type</div>

                          </div>
                          <div class="bottom-line"></div>
                          <div class="bottom-item">
                            <div class="item-value item-value-sales-revenue" :title="item.attendee.name">{{item.attendee.name ? item.attendee.name : '--'}}</div>
                            <div class="item-label">Name</div>

                          </div>
                          <!--                          <div class="bottom-line"></div>-->
                          <!--                          <div class="bottom-item">-->
                          <!--                            <div class="item-value" :title="item.company_type">{{item.company_type}}</div>-->
                          <!--                            <div class="item-label"> Type </div>-->
                          <!--                          </div>-->
                          <div class="bottom-line"></div>
                          <div class="bottom-item">
                            <div class="item-value">{{item.attendee.title ? item.attendee.title : '--'}}</div>
                            <div class="item-label">Title</div>

                          </div>
                        </div>
                        <div class="item-industries" v-if="item.industry">
                          <div class="item-industry" v-for="(vItem,vIndex) in item.industry.split(',')" :key="vIndex" >{{vItem}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="no-data" v-if="tradeCompanies.length == 0">
                      No Companies
                    </div>
                  </div>


                </div>
              </div>


            </div>
          </template>





        </div>
<!--        <div class="load-more" v-if="!loading">-->
<!--          <div class="prev" title="Prev Page">-->
<!--            <img src="@/assets/contact/prev.png" v-if="page > 1" @click="prevPage()"/>-->
<!--            <img src="@/assets/contact/prev-2.png" v-else/>-->
<!--          </div>-->
<!--          <div class="next" title="Next Page">-->
<!--            <img src="@/assets/contact/next.png" @click="nextPage()" v-if="pageData.total / pageData.per_page > page"/>-->
<!--            <img src="@/assets/contact/next-2.png" v-else/>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    <AttendShow ref="attendShow" ></AttendShow>
    <PopLogin ref="popLogin" @register="register"></PopLogin>
    <AttendedCompanyList ref="attendedCompanyList" @showContractForm="showContractForm" @showBatchCheck="showBatchCheck"></AttendedCompanyList>
    <AttendedShowList ref="attendedShowList" ></AttendedShowList>
    <CompanyCompleteInfo ref="companyCompleteInfo" @notLogin="notLogin"></CompanyCompleteInfo>
    <PopRegisterEmail
      ref="popRegisterEmail"
      @login="login"
    ></PopRegisterEmail>
    <!-- <TestMagicUi ref="testMagicUi" ></TestMagicUi> -->
  </div>
</template>
<script>
  import api from "@/api";
  // import { ref } from 'vue';

  import store from "@/store";
  import { mapState } from "vuex";
  import "swiper/swiper-bundle.min.css";
  import Swiper, { Autoplay } from "swiper";
  Swiper.use([Autoplay]);
  const {
    _getTradeShow,_getIsAttendTradeShow,_AttendTheTradeShow,_getAllTradeShowCompanies
    ,_contactTradeShowCompany,_getAllTradeShowBannerCompanies,_getTradeShowConfig,
    _dataSearchTypeCount
  } = api;
  import PopLogin from "@/components/popLogin";
  import PopRegister from "@/components/popRegister";
  import PopRegisterSuccess from "@/components/popRegisterSuccess";
  import PopRegisterEmail from "@/components/popRegisterEmail";
  import AttendShow from "@/components/attendShow";
  import AttendedCompanyList from "@/components/attendedCompanyList";
  import AttendedShowList from "@/components/attendedShowList";
  import CompanyCompleteInfo from "@/components/companyCompleteInfo";


  export default {
    name: "products",
    computed: {
      ...mapState("user", ["role"]),
      processedItems() {
        const months = {};
        return this.shows.map(item => {
          if (!months[item.month]) {
            months[item.month] = true;
            return { ...item, isFirstOfMonth: true };
          } else {
            return { ...item, isFirstOfMonth: false };
          }
        });
      }
    },
    components: {
      PopLogin,
      PopRegister,
      PopRegisterSuccess,
      PopRegisterEmail,
      AttendShow,AttendedCompanyList,AttendedShowList,Swiper,CompanyCompleteInfo
    },
    data() {
      return {
        helpData: [],
        showMaxData: false,
        activeIndex: 1,
        intervalId: null,
        months: ['2024-08', '2024-09', '2024-10', '2024-11'],
        currentMonth: 'all',
        dataByMonth: {
          '2024-08': [{ id: 1, name: 'Data 1' }, { id: 2, name: 'Data 2' }],
          '2024-09': [{ id: 3, name: 'Data 3' }, { id: 4, name: 'Data 4' }],
          // 更多月份数据
        },
        tradeCompanies: [],
        hasStore: false,
        shows: [],
        pageData: {total: 0,per_page:10},
        loading: false,
        pageNum : 16,
        page: 1,
        isCreateStore: false,
        showType: 1,
        productName: '',
        showFilterCompany: false,
        filter:{
          industry: 0,
          start_date: null,
          end_date: null,
          company_name: null,
          product_name: null,
          title: null,
          company_type:null,
          purpose: null
        },
        swiper: {},
        swiper1: {},
        swiper2: {},
        swiperCompanies: [],
        swiper1Companies: [],
        swiper2Companies: [],
        title: [],
        // companyType: [],
        // industry: [],
        titleArr: ['Procurement','Sales','Marketing','R&D','QC/Lab','Regulatory','Technical','Production','Executives','Others'
        ],
        industryArr: [],
        adsShow: {},
        companyType: [
          'Distributor/Wholesales',
          'Mnfr/Producer/Factory',
          'Brand Owner',
          'Contract Mnfr/OEM/ODM/Copacker',
          'Retailer/SuperMarket',
          'Farm',
          'Media/Publisher/Association',
          'Consulting',
          'Formulation/Solution/Innovation',
          'Lab Testing',
          'Service Provider'
        ],
        industry: [
          'Dietary Supplement', 'Food', 'Beverage', 'Cosmetics/Personal care', 'Pet Food',
          'Feed', 'Pharmaceutical industries', 'Others'
        ],


      };
    },
    beforeDestroy() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    mounted() {
      let u = this.$route.query.u;
      if (typeof u != "undefined"  && "" === this.role) {
        this.$refs.popRegisterEmail.showLogin = true;
      }
      this.intervalId = setInterval(() => {
        this.activeIndex = (this.activeIndex + 1) % 7;
      }, 2000);
      this.containerRef = this.$refs.containerRef;
    this.div1Ref = this.$refs.div1Ref;
    this.div2Ref = this.$refs.div2Ref;
      this.getShows();
      this.getIsAttendTradeShow();
      this.$nextTick(function() {
        this.getAllTradeShowBannerCompanies();
      });
      window.addEventListener("scroll", this.onMidScroll, true);
      this.getTradeShowConfig();

    },
    filters: {

    },
    methods: {
      login() {
        this.$refs.popRegisterEmail.showLogin = false;
        this.$refs.popLogin.showLogin = true;
      },
      register() {
        this.$refs.popLogin.showLogin = false;
        this.$refs.popRegisterEmail.showLogin = true;
      },
      notLogin(){
        this.$refs.popLogin.showLogin = true;
        return;
      },
      showBatchCheck(index,num){
        if (this.role === "") {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        // this.shows[index].storefront_count = num;
        if(num > 0){
          this.shows[index].storefront_count++;
        }else{
          this.shows[index].storefront_count--;
        }
        this.shows[index].storefront_attend_count = num;

        var id = this.shows[index].id;

        _getIsAttendTradeShow()
          .then(response => {
            if(response.data){
              this.attendTheShow(id);
              return;
            }

            this.$refs.attendShow.show = true;

          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.$refs.attendedCompanyList.trade_show.storefront_attend_count = num;

          });
      },
      formatDate(dateStr) {
        const [monthStr, year] = dateStr.split('/');
        const monthNum = parseInt(monthStr, 10) - 1;
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const monthName = months[monthNum];

        return `${monthName} ${year}`;
      },
      jumpTo(path) {
        this.$router.push(path);
      },
      getSearchTypeCount(){
        _dataSearchTypeCount({
          s: this.filter.product_name,
        })
          .then(response => {
            this.helpData = response.data;
            if(this.helpData.length > 0){
              this.showMaxData = true;
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
          });
      },
      changeSearchType(val) {
        this.filter.company_type = val;
        this.getTradeCompanies();
      },
      changePurpose(val) {
        this.filter.purpose = val;
        this.getTradeCompanies();
      },
      changeIndustry(val) {
        this.filter.industry = val;
        this.getTradeCompanies();
      },
      changeTitle(val) {
        this.filter.title = val;
        this.getTradeCompanies();
      },
      getTradeShowConfig(){
        _getTradeShowConfig()
          .then(response => {
            this.title = response.data[0];
            this.company_type = response.data[0];
            this.industryArr = response.data[2];
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
          });
      },
      createCompany(){
        if (this.role === "") {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        this.$refs.attendShow.show = true;
        this.$refs.attendShow.getMyStoreFront();
        return;
        if(this.hasStore){
          _contactTradeShowCompany({id:id,show_id:show_id})
            .then(response => {
              console.log(response);
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {

            });
        }else{
          this.$refs.attendShow.show = true;
        }
      },
      viewCompany(){
        this.showType = this.showType == 1 ? 2 : 1;
        this.searchShows();
      },
      onMidScroll() {
        let offsetTopArr = [];
        let anchors = document.querySelectorAll(".first-month");
        anchors.forEach(i => { offsetTopArr.push(i.offsetTop) });
        let content = document.querySelector("html");
        let scrollTop = content.scrollTop;
        var curIndex = 0;
        for (let i = 0; i < offsetTopArr.length; i++) {
          if (scrollTop + 220 >= offsetTopArr[i]) {
            curIndex = i;
          }
        }
        // console.log(curIndex);
        this.currentMonth = this.months[curIndex]
        // this.selected = curIndex;
      },
      setCurrentMonth(month) {
        this.currentMonth = month;
        var element = document.getElementById('first-'+month);
        element.scrollIntoView();
      },

      showCompanies(){
        this.showType = 2;
        this.searchShows();
      },
      showExhibitor(val){
        if (val.indexOf("+") == -1) {
          return val+'+';
        }else{
          return val;
        }
      },
      getAllTradeShowBannerCompanies(){
        _getAllTradeShowBannerCompanies()
          .then(response => {
            if(response.code === 0){
              var companiesData = response.data;
              this.swiperCompanies = companiesData[0];
              this.swiper1Companies = companiesData[1];
              this.swiper2Companies = companiesData[2];

            }

          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.swiper = new Swiper(".swiper", {
              autoplay: {
                delay: 0,
                disableOnInteraction: false
              },
              loop: true,
              speed: 1700,
              freeMode: true,
              freeModeMomentum: true,
              direction: "vertical",
              slidesPerView: 5,
              spaceBetween: 20,
              autoplayDisableOnInteraction: false,
              observer: true,
              observeParents: false,
            });
            this.swiper1 = new Swiper(".swiper1", {
              autoplay: {
                delay: 0,
                disableOnInteraction: false,
                reverseDirection: true,
              },
              speed: 1700,
              loop: true,
              freeMode: true,
              freeModeMomentum: true,
              direction: "vertical",
              slidesPerView: 5,
              spaceBetween: 20,
              autoplayDisableOnInteraction: false,
              observer: true,
              observeParents: false,
            });
            this.swiper2 = new Swiper(".swiper2", {
              autoplay: {
                delay: 0,
                disableOnInteraction: false
              },
              speed: 1700,
              loop: true,
              freeMode: true,
              freeModeMomentum: true,
              direction: "vertical",
              slidesPerView: 5,
              spaceBetween: 20,
              autoplayDisableOnInteraction: false,
              observer: true,
              observeParents: false,
            });
          });
      },
      isValidDomain(str) {
        const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return domainRegex.test(str);
      },
      ensureHttps(url) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return 'https://' + url;
        }
        return url;
      },
      showDetail(val){
        if(this.isValidDomain(val.website)){
          // console.log(val.website);
          window.open(this.ensureHttps(val.website), '_blank');
        }
      },
      getIsAttendTradeShow(){
        _getIsAttendTradeShow()
          .then(response => {
            if(response.data){
              this.hasStore = true;
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {

          });
      },
      searchShows(){
        if(this.showType == 1){
          this.getShows();
        }else{
          this.getTradeCompanies();
          this.getSearchTypeCount();
        }
      },
      showCompanyShows(val){
        this.$refs.companyCompleteInfo.show = true;
        this.$refs.companyCompleteInfo.company_list = val;
      },
      showContractForm(id,show_id){
        if (this.role === "") {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if(this.hasStore){
          this.$refs.attendedCompanyList.sendingEmail = true;
          _contactTradeShowCompany({id:id,show_id:show_id})
            .then(response => {
              console.log(response);
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.$refs.attendedCompanyList.sendingEmail = false;
              this.$Message.success("Email sent successfully!");
            });
        }else{
          this.$refs.attendShow.show = true;
        }
      },
      changeType(){
        if(this.showType == 2){
          this.getTradeCompanies();
        }

      },
      getTradeCompanies(){
        this.loading = true;
        this.tradeCompanies = [];
        _getAllTradeShowCompanies({page:this.page,limit:this.pageNum,...this.filter})
          .then(response => {
            const { data } = response;
            this.tradeCompanies = data[0];
            // this.industry = data[1][1];
            // this.companyType = data[1][0];
            // this.titleArr = data[1][2];
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      batchCheck(index,num){
        if (this.role === "") {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        this.shows[index].storefront_attend_count = num;
        var id = this.shows[index].id;
        if(num > 0){
          this.shows[index].storefront_count++;
        }else{
          this.shows[index].storefront_count--;
        }

        _getIsAttendTradeShow()
          .then(response => {
            if(response.data){
              this.attendTheShow(id);
              return;
            }

            this.$refs.attendShow.show = true;

          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {

          });



      },
      nextPage(){
        this.page++;
        this.getShows();
      },
      prevPage(){
        this.page--;
        this.getShows();
      },
      attendShow(id){
        if (this.role === "") {
          this.$refs.popLogin.showLogin = true;
        }else{
          this.getIsAttend(id);

        }
      },
      companyList(val,index=0){
        this.$refs.attendedCompanyList.showContacts = true;
        this.$refs.attendedCompanyList.trade_show = val;
        this.$refs.attendedCompanyList.index = index;
      },
      getIsAttend(id){
        let storeId = id;
        _getIsAttendTradeShow()
          .then(response => {
            if(response.data){
              this.attendTheShow(storeId);
              return;
            }
            
            this.$refs.attendShow.show = true;

          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
           
          });
      },
      attendTheShow(id){
        _AttendTheTradeShow({id:id})
          .then(response => {
            if(response.data){
              this.$Message.success("Success!");
            }
            

          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
           
          });
      },
      getImagePath(id){
        // if(id ==5){
        //   var imagePath = require(`@/assets/trade-show/banner-${id}.gif`);
        // }
        if(id == 4 || id ==8 || id ==5){
          
        }else{
          var imagePath = require(`@/assets/trade-show/banner-${id}.png`);
        }
        return imagePath;
      },
      changeDateAndRemoveYear(originalDate){
        const date = new Date(originalDate);
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const year = date.getUTCFullYear();
        return  `${month}/${day}`;
      },
      changeDate(originalDate){
        const date = new Date(originalDate);
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const year = date.getUTCFullYear();
        return  `${month}/${day}/${year}`;
      },
      getShows() {
        this.loading = true;
        this.shows = [];
        _getTradeShow({page:this.page,limit:this.pageNum,...this.filter})
          .then(response => {
            const { data } = response;
            this.pageData = data[0];
            this.months = data[0];
            this.shows = this.dataByMonth = data[1];
            this.isCreateStore = data[2];
            this.adsShow = data[3];
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>
<style lang="less" scoped>
  .main-content{
    padding-bottom: 50px !important;
    z-index: 8;
    position: sticky;
    background: #efefef;
    margin-top: 0 !important;
    padding-top: 10px;
  }
  .swiper-container{
    width: 100%;
    height: 450px;
  }

  .swiper-slide {
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px !important;
    height: 135px !important;
    cursor: pointer;
    border-radius: 10px;
  }
  .banner{
    height: 450px;
    /*background-image: linear-gradient(130deg, #F7EEFE, #FAE9EC);;*/
    background: #120D21;
    margin-top: 77px;
    .banner-content{
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    .banner-left{
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      .color1{

        font-size: 36px;
      }
      .color{
      /*background: linear-gradient(to right, #5DBAFF, #FFA5CB, #DB537F, #615AD3);*/
      /*  background-clip: text;*/
      /*  -webkit-text-fill-color: transparent;*/
      /*  -moz-text-fill-color: transparent;*/
      /*  -o-text-fill-color: transparent;*/
      /*  font-size: 36px;*/
        margin-top: 5px;
        img{
          width: 530px;
        }
      }
    }
    .banner-left-bottom{
      color: #C7C7C7;
      font-size: 18px;
      margin-top: 15px;
      font-weight: 500;
    }
    .banner-btns{
      display: flex;
      font-size: 16px;
      font-weight: 400;
      margin-top: 45px;
      .banner-btn{

          display: flex;
        justify-content: center;
        align-items: center;

        padding: 13px 25px;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
      .create-btn{
        margin-right: 20px;
        border: 1px solid #fff;
        color: #fff;
        &:hover{
          border: none;
          background: linear-gradient(0deg,#A47CF3,#683FEA);
          box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
          inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
          0px 0px 0px 4px rgba(255, 255, 255, 0.2),
          0px 0px 180px 0px #9917FF;
          transform: translateY(-2px);
          color: #fff;
        }

      }

      .view-btn{
        background: #fff;
        color: #000;
        &:hover {
          background: linear-gradient(0deg,#A47CF3,#683FEA);
          box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
          inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
          0px 0px 0px 4px rgba(255, 255, 255, 0.2),
          0px 0px 180px 0px #9917FF;
          transform: translateY(-2px);
          color: #fff;
        }

      }
    }

    .banner-right{
      display: flex;
      align-items: center;
      /*margin-right: 150px;*/
      /*width: 800px;*/
      /*img{*/
      /*  height: 320px;*/
      /*}*/
    }
  }
  .sc-width{
    margin: 50px auto 0 auto;
    padding-bottom: 0px;
  }
  .intro-date{
    margin: 10px auto 30px auto;
    width: 1600px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background: #fff;
    /*border: 2px solid rgba(118, 70, 216, 0.6) ;*/
    padding: 10px 20px;
    .left-date{
      display: flex;
      align-items: center;

      img{
        width: 500px;
        cursor: pointer;
      }
      .left-w{
        font-size: 14px;
        font-weight: 500;
        color: #000;
        margin-left: 60px;
        img{
          width: 22px;
          position: relative;
          top: 5px;
          margin-right: 2px;

        }
        .date-address{
          margin-left: 26px;
        }
      }
    }
    .right-date{
      margin-top: 40px;
      /*display: flex;*/
      align-items: center;
      margin-right: 30px;
      .right-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 17px;
        border-radius: 15px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        border: 1px solid #7646d8;
        color: #7646d8;
        &:hover{
          opacity: 0.8;
          background: #3E1DCE;
          color: #fff;
        }
      }
      .date-time{
        font-size: 16px;
        font-weight: 500;
        color: #000;

      }
      .date-address{
        font-size: 18px;
        font-weight: 500;
        color: #000;
        margin-left: 10px;
      }
    }
  }
  .intro{
    display: flex;
    justify-content: space-between;
    .intro-left{
      font-size: 20px;
      font-weight: 600;
    }
    .intro-right{
      font-size: 18px;
      font-weight: 500;
      color: #919191;
    }
  }
  .all-data{
    display: flex;
    /*flex-wrap: wrap;*/
    .loading-template{
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      padding-bottom: 50px;
      img{
        width: 50px;
      }
    }
    .not-current-month{
      /*border: none !important;*/
      /*opacity: 0.8;*/
      /*-webkit-filter: blur(5px); !* Chrome, Opera *!*/
      /*-moz-filter: blur(5px);*/
      /*-ms-filter: blur(5px);*/
      /*filter: blur(5px);*/
      /*transition: opacity 0.5s;*/
      box-shadow: none !important;
    }
    .data-item{
      border-radius: 10px;
      margin: 12px 12px;
      width: 338px;
      /*height: 225px;*/
      background: #fff;
      /*cursor: pointer;*/
      /*opacity: 0.4;*/
      /*border: 2px solid rgba(118, 70, 216, 0.6) ;*/
      box-shadow: 2px 2px 5px rgba(118, 70, 216, 0.5);
      .item-top{
        height: 200px;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        .item-top-content{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 25px;
          font-weight: 700;
          word-wrap: break-word;
          padding: 20px;
          background: linear-gradient(to right, #5DBAFF, #FFA5CB, #DB537F, #615AD3);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
        }
      }
      .item-bottom{
        display: flex;
        padding: 20px;
        border-top: 1px solid #D9D9D9;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        flex-wrap: wrap;
        justify-content: space-between;
        .bottom-name{
          font-weight: 600;
          font-size: 17px;
          margin-bottom: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        .bottom-item{
          margin: 10px 0;
          width: 45%;
          display: flex;
          .item-icon{
            img{
              width: 17px;
              position: relative;
              top: 2px;
              margin-right: 4px;
            }
          }
          .item-label{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 15px;
          }
        }
        .bottom-item-date{
          width: 160px;
        }
        .bottom-btn{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 35px;
          border-radius: 5px;
          font-size: 15px;
          margin-top: 10px;
          cursor: pointer;

        }
        .btn-check{
          margin-left: 10px;
          img{
            width:24px;
            height: 24px;
            position: relative;
            top: 10px;
          }
        }
        .item-btn{
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F4F4F4;
          color: #222;
          font-size: 14px;
          cursor: pointer;
          border-radius: 6px;
          padding: 8px 20px;
          width: 100%;
          margin: 20px auto 5px auto;
          &:hover{
            opacity: 0.8;
            background: #3E1DCE;
            color: #fff;
          }
        }
        .item-btn-company-list{
          background: #fff !important;
          border: 1px solid #e5e5e5;
          &:hover{
            opacity: 0.9;
            background: #fff;
            color: #3E1DCE;
            border: 1px solid #3E1DCE;
          }
        }
      }

      &:hover{
        opacity: 1 !important;
        filter: blur(0) !important;
      }
      &::before {

      }
    }
  }
  .search-left{
    margin-left: 150px;
  }
  .search-left-2{
    margin-left: 220px;
  }
  .search{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .left-search{
      display: flex;
      justify-self: start;
    }
    .right-search{
      margin-right: 40px;
      select{
        height: 40px;
        padding: 0 14px;
        border-radius: 5px;
        outline: none;
        border: 1px solid #eee;
        font-size: 17px;

      }
    }
    .industry{
      margin-right: 20px;
      span{
        font-size: 17px;
        font-weight: 500;
        margin-right: 8px;
      }
      select{
        height: 40px;
        padding: 0 10px;
        border-radius: 6px;
        outline: none;
        border: 1px solid #eee;
        font-size: 17px;
      }
    }
    .product{
      margin-right: 2px;
      div{
        display: flex;
        span{
          display: flex;
          align-items: center;
          margin-right: 5px;
        }
      }
      .title{
        margin-left: 15px;
      }
      span{
        font-size: 17px;
        font-weight: 500;
        margin-right: 8px;
      }
      input{
        height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        outline: none;
        border:1px solid #eee;
        font-size: 17px;
        width: 300px;
      }
    }
    .date{
      span{
        font-size: 17px;
        font-weight: 500;
        margin-right: 8px;
      }
      input{
        height: 40px;
        padding: 0 3px;
        border-radius: 5px;
        outline: none;
        border:1px solid #eee;
        font-size: 17px;
      }
      .start-date{
        margin-right: 4px;
      }
    }
    .btn{
      background: #f57f45;
      color: #fff;
      width: 90px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      border-radius: 5px;
      margin-left: 15px;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }
    .btn-batch{
      border: 1px solid #3E1DCE;
      color: #3E1DCE;
      width: 120px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      border-radius: 5px;
      margin-left: 15px;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }
  }
  .load-more{
    display: flex;
    justify-content: center;
    margin-top: 15px;
    padding-bottom: 20px;
    div{
      cursor: pointer;
      img{
        width: 14px;
      }
    }
    .prev{
      margin-right: 18px;
    }
  }
  .tagcloud-all {
    position: relative;
    width: 1600px;
    height: 1200px;
    background: #fff;
    a {
      position: absolute;
      top: 0px;
      left: 0px;
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      padding: 3px 6px;
      img{
        width: 95px;
      }
      &:hover {
        color: #FF0000;
        letter-spacing: 2px;
      }
    }
  }

  .companies {
    /*.companies-content:after{*/
    /*  content: '';*/
    /*  width: 380px;*/
    /*}*/
    .companies-content{
      display: flex;
      justify-content: space-between;
      margin-top: 20px !important;
    }
    .companies-content  .right-content {

      /*margin-top: 15px !important;*/
      width: 1400px;
      .main-companies{
        display: flex;
        /*justify-content: space-between;*/
        flex-wrap: wrap;
      }

      .item {
        width: 330px;
        padding: 15px;
        /*border: 1px solid #b9b9b9;*/
        /*border-radius: 10px;*/
        cursor: pointer;
        margin: 8px 8px;

        .item-top {
          display: flex;
          justify-content: space-between;

          .top-item {
            width: 85px;
            height: 60px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30px;
            }

          }

          .top-item-1 {
            background-image: linear-gradient(90deg, #9d4ee5, #3f55dd);
          }

          .top-item-2 {
            background-image: linear-gradient(90deg, #e94231, #f1cc00);
          }

          .top-item-3 {
            background-image: linear-gradient(90deg, #0c5bfa, #f66e7c);
          }

          .top-item-4 {
            background-image: linear-gradient(90deg, #d0295b, #ed7b28);
          }

        }

        .item-mid {
          /*position: relative;*/
          display: flex;
          /*bottom: 25px;*/
          padding: 5px 0 15px 0;
        }

        .item-logo {
          display: flex;
          justify-content: center;
          width: 70px;
          height: 70px;
          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }

        }
        .item-right{
          margin-left: 15px;
        }

        .item-name {
          /*display: flex;*/
          justify-content: center;
          color: #2F2F2F;
          font-size: 18px;
          font-weight: 700;
          margin-top: 10px;
          width: 210px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;


        }

        .item-location {
          /*display: flex;*/
          /*justify-content: center;*/
          color: #949494;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 7px;
          margin-top: 4px;
        }

        .item-bottom {
          display: flex;
          justify-content: space-between;
          /*position: relative;*/
          /*padding-left:10px;*/
          /*padding-right:10px;*/
          /*bottom: 15px;*/
          .bottom-item {
            .item-value {
              color: #2F2F2F;
              font-size: 15px;
              font-weight: 600;
              /*display: flex;*/
              justify-content: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 90px;
              text-align: center;
            }
            .item-value-sales-revenue{
              /*width: 180px;*/
            }

            .item-label {
              color: #949494;
              font-size: 14px;
              display: flex;
              justify-content: center;
            }
          }

          .bottom-line {
            background: #D9D9D9;
            width: 1px;
            height: 40px;
          }
        }
        .item-bottom-2{
          margin-top: 15px;
        }
      }
    }
  }
  .companies-swiper {
    .companies-content:after{
      content: '';
      width: 100%;
    }
    .companies-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      /*margin-top: 2px !important;*/

      .item {
        width: 100%;
        padding: 15px 10px;
        /*border: 1px solid #b9b9b9;*/
        /*border-radius: 10px;*/
        cursor: pointer;
        /*margin: 12px 0;*/

        .item-top {
          display: flex;
          justify-content: space-between;

          .top-item {
            width: 85px;
            height: 60px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30px;
            }

          }

          .top-item-1 {
            background-image: linear-gradient(90deg, #9d4ee5, #3f55dd);
          }

          .top-item-2 {
            background-image: linear-gradient(90deg, #e94231, #f1cc00);
          }

          .top-item-3 {
            background-image: linear-gradient(90deg, #0c5bfa, #f66e7c);
          }

          .top-item-4 {
            background-image: linear-gradient(90deg, #d0295b, #ed7b28);
          }

        }

        .item-mid {
          /*position: relative;*/
          /*display: flex;*/
          /*bottom: 25px;*/
          /*padding: 5px 0 25px 0;*/
        }

        .item-logo {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }

        }
        .item-right{
          margin-top: 15px;
        }

        .item-name {
          display: flex;
          justify-content: center;
          color: #2F2F2F;
          font-size: 16px;
          font-weight: 600;
          /*margin-top: 10px;*/
          text-align: center;

        }

        .item-location {
          /*display: flex;*/
          /*justify-content: center;*/
          color: #949494;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 7px;
          margin-top: 4px;
        }

        .item-bottom {
          display: flex;
          justify-content: space-between;
          /*position: relative;*/
          padding-left:10px;
          padding-right:10px;
          /*bottom: 15px;*/
          .bottom-item {
            .item-value {
              color: #2F2F2F;
              font-size: 16px;
              font-weight: 600;
              /*display: flex;*/
              justify-content: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 90px;
              text-align: center;
            }

            .item-label {
              color: #949494;
              font-size: 14px;
              display: flex;
              justify-content: center;
            }
          }

          .bottom-line {
            background: #D9D9D9;
            width: 1px;
            height: 40px;
          }
        }
      }
    }
  }
  .animatedBorder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /*width: 300px;*/
    /*min-height: 240px;*/
    border-radius: 10px;
    /*border-color: hsl(240 10% 3.9% / .1);*/
   height: fit-content;
   box-shadow: 2px 2px 5px rgba(118, 70, 216, 0.5);
   background: #fff;
 }
 .animatedBorder:before {
   /*content: "";*/
    /*position: absolute;*/
    /*width: calc(100% + 300px);*/
    /*height: calc(100% + 300px);*/
    /*opacity: .3;*/
  }
  .animatedBorder:before {
    /*background-image: conic-gradient(transparent, transparent, transparent, #d400d4, transparent);*/
    /*rotate: 45deg;*/
  }
  .animatedBorder:after {
    /*background-image: conic-gradient(transparent, transparent, transparent, #0cf, transparent);*/
    /*rotate: 225deg;*/
  }
  .animatedBorder:after, .animatedBorder:before {
    /*content: "";*/
    /*position: absolute;*/
    /*width: calc(100% + 300px);*/
    /*height: calc(100% + 300px);*/
    /*opacity: .3;*/
  }

  .animatedBorder .item-wrap {
    width: 100%;
    /*z-index: 5;*/
    /*border-radius: 12px;*/
    /*background-color: #fff;*/
    /*position: absolute;*/
    /*inset: 2px;*/
    /*padding: 20px 15px;*/
  }
  .animatedBorder:hover:after, .animatedBorder:hover:before {
    /*opacity: 1;*/
    /*animation: animate 3s linear infinite;*/
  }
  @keyframes animate {
    0% {
      transform: rotate(0deg)
    }

    to {
      transform: rotate(1turn)
    }
  }
  .no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    font-size: 15px;
  }
  .left-part{
    position: sticky;
    top: 140px;
    bottom: 0;
    height: fit-content;
  }
  .left-part .left-months{
    width: 120px;
    margin-right: 25px;
    margin-top: 15px;
    background: #fff;
    padding: 22px 12px;
    border-radius: 10px;
    position: relative;
    bottom: 60px;
    div{
      padding: 8px 8px;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      margin-bottom: 10px;
      color: #7f8e91;
      display: flex;
      justify-content: center;
      align-items: center;

    }
    .active{
      color: #e7d7ff;
      /*font-size: 16px;*/
      font-weight: 700;
      background: #7646d8;
      border-radius: 10px;
      cursor: auto;
    }

  }
  .right-part{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 110px;
  }

  .left-view{
    width: 205px;
    border-right: 1px solid #eaecf1;
    margin-right: 10px;
    background: #fff;
    padding: 20px 10px;
    border-radius: 20px;
    position: relative;
    bottom: 70px;
    .view-item{
      .view-item-title{
        font-size: 17px;
        color: #272727;
        font-weight: 600;
        /*margin-top: 10px;*/
      }
      .view-item-list{
        .view-item-list-item{
          /*width: 200px;*/
          margin: 7px 0;
          cursor: pointer;
          padding: 4px 8px;
          .view-list-word{
            color: #8F8F8F;
            font-size: 16px;
            font-weight: 500;
            display: flex;

            div{
              width: 195px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .view-item-list-item-active{
          background: #efeeea;
          border-radius: 5px;
          .view-list-word{
            color: #777;
            font-weight: 600;
          }
        }
      }
    }
    .view-item-line{
      background: #eaecf1;
      height: 1px;
      width: 130px;
      margin: 15px 0;
    }
  }
  .loading-template-type-2{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 50px;
    img{
      width: 50px;
      height: 50px;
    }
  }
  input.product-name{
    width: 400px !important;
  }

  .research-radar-wrapper {
    display: flex;
    justify-content: center;
  }
  .research-radar {
    height: 800px;
    position: relative;
    width: 800px;
    min-width: 800px;
    margin-top: 380px;
    margin-right: 50px;
  }
  .research-radar:before {
    background: linear-gradient(to left, rgba(243, 238, 255, .08) -.89%, rgba(243, 238, 255, 0) 100%);
    content: "";
    height: 1px;
    position: absolute;
    right: -23px;
    top: 50%;
    width: 680px;
    z-index: 2;
  }
  .research-radar-background {
    height: 100%;
    width: 100%;
  }
  .lazy-image {
    display: block;
    transition: 1s cubic-bezier(.6,.6,0,1) opacity;
  }
  .research-radar-logo {
    height: 60px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    z-index: 1;
  }
  .research-radar-scanner-wrapper {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .research-radar-scanner-wrapper-visible .research-radar-scanner {
    animation: researchRadarRotate 7s infinite linear;
  }
  .research-radar-scanner {
    -webkit-clip-path: polygon(0 0, 93.3673469388% 0, 93.3673469388% 25.1275510204%, 50% 50.1275510204%, 0 50.1275510204%);
    clip-path: polygon(0 0, 93.3673469388% 0, 93.3673469388% 25.1275510204%, 50% 50.1275510204%, 0 50.1275510204%);
    height: 800px;
    left: 0;
    left: 0px;
    position: absolute;
    top: 0;
    top:5px;
    transform: rotate(-201deg);
    width: 800px;
  }

  @keyframes researchRadarRotate {
    0%{
      transform: rotate(-201deg);
    }
    100%{
      transform: rotate(159deg);
    }
  }
  .research-radar-scanner:before {
    background: linear-gradient(267deg, rgba(99, 102, 241, .4) 13.23%, rgba(3, 0, 20, 0) 50%);
    border-radius: 50%;
    display: block;
    height: 100%;
    width: 100%;
  }
  .research-radar-scanner:before, .research-radar-scanner:after {
    content: "";
  }
  .research-radar-scanner:after {
    background: linear-gradient(to bottom, rgba(120, 123, 255, .24) .11%, rgba(120, 123, 255, 0) 100%), linear-gradient(to bottom, rgba(255, 255, 255, .24) .11%, rgba(255, 255, 255, 0) 100%);
    height: 392px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: rotate(60deg);
    transform-origin: bottom;
    width: 1px;
  }
  .research-radar-fader {
    display: none;
    background: #120D21;
    height: 200px;
    left: 50%;
    position: absolute;
    top: 226px;
    transform: translate(-50%);
    width: 500px;
  }
  .research-radar-item:nth-child(1) {
    left: 395px;
    top: 107px;
  }
  .research-radar-item:nth-child(2) {
    left: 203px;
    top: 395px;
  }
  .research-radar-item:nth-child(3) {
    left: 147px;
    top: 251px;
  }
  .research-radar-item:nth-child(4) {
    left: 299px;
    top: 228px;
  }
  .research-radar-item:nth-child(5) {
    left: 645px;
    top: 251px;
  }
  .research-radar-item:nth-child(6) {
    left: 491px;
    top: 229px;
  }
  .research-radar-item:nth-child(7) {
    left: 587px;
    top: 395px;
  }
  .research-radar-item div:nth-child(1) {
    height: 100px;
    width: 100px;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
  }
  .research-radar-item div:nth-child(2) {
    height: 68px;
    width: 68px;
    left: calc(50% - 34px);
    top: calc(50% - 34px);
  }
  .research-radar-item div:nth-child(3) {
    height: 36px;
    width: 36px;
    left: calc(50% - 18px);
    top: calc(50% - 18px);
  }

  .research-radar-item div:nth-child(4) {
    height: 12px;
    width: 12px;
    left: calc(50% - 6px);
    top: calc(50% - 6px);
    background: rgba(109,72,255,.16);
    box-shadow: inset 0 0 6px 4px #ed78ff0a,inset 0 0 8px #787bff0f,inset 0 0 4px #787bff0a;
  }
  .research-radar-item div:nth-child(5) {
    height: 8px;
    width: 8px;
    left: calc(50% - 4px);
    top: calc(50% - 4px);
    background: rgba(109,72,255,.48);
    box-shadow: inset 0 0 6px 4px #ed78ff0a,inset 0 0 8px #787bff0f,inset 0 0 4px #787bff0a;
  }
  .research-radar-item div:nth-child(6) {
    height: 20px;
    width: 20px;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    background: rgba(109,72,255,.76);
    filter: blur(8px);
    mix-blend-mode: screen;
    transform: scaleY(-1);
  }
  .research-radar-item div {
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    transform: scale(0);
  }
  .research-radar-item-active div {
    animation: researchRadarItem 3s calc(var(--index) * .01s) cubic-bezier(.6,.6,0,1) 1;
  }
  .research-radar-item div:nth-child(1), .research-radar-item div:nth-child(2), .research-radar-item div:nth-child(3) {
    background: rgba(109,72,255,.04);
  }
  .research-radar-item {
    height: 100px;
    position: absolute;
    width: 100px;
  }
  @keyframes
  researchRadarItem
  {0%{opacity:0;transform:scale(0)}
    25%{opacity:1}
    50%,75%{opacity:1;transform:scale(1)}to
                                         {opacity:0;transform:scale(1.2)}}
  .maxdata{
    display: flex;
  }
  .help{
    border: 2px solid #1E828C;
    background: rgba(0, 124, 137, 0.1618);
    /*width: 700px;*/
    /*height: 150px;*/
    border-radius: 15px;
    padding: 15px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: fit-content;
    .help-top{
      .checkbox{
        input{
          height: 20px;
          width: 20px;
        }

        margin-right: 5px;
        margin-top: 1px;
      }
      .help-title{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .help-desc{
      font-size: 13px;
      /*color: #9D9D9D;*/
      margin: 0 0 10px 25px;
    }
    .help-contents{
      display: flex;
      &:last-child{
        margin-right: 0 !important;
      }
      .help-item{
        background: #fff;
        border: 1px solid #C2C2C2;
        border-radius: 10px;
        padding: 10px;
        margin-right: 15px;

        .help-item-top{
          margin: 0 auto;
          font-size: 16px;
          font-weight: 600;
          color: #FF4835;
          text-align: center;
        }
        .help-item-line{
          background: #E2E2E2;
          height: 1px;
          margin: 5px auto;
        }
        .help-item-name{
          margin: 0 auto;
          font-size: 13px;
          /*font-weight: 500;*/
          text-align: center;
        }
      }
      .help-item-last{
        margin-right: 0;
      }
    }
    .help-btn{
      background: #007c89;
      border-radius: 15px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      width: fit-content;
      margin-top: 20px;
      cursor: pointer;
      &:hover{
        opacity: 0.9;
      }
    }
  }
  .help-sales{
    margin-left: 20px;
  }
  .help-trade{
    margin-left: 20px;
  }
  .item-industries{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 15px;
    .item-industry{
      color: #676767;
      font-weight: 500;
      /*width: 120px;*/
      background: #efefef;
      border-radius: 4px;
      padding: 5px 8px;
      margin-bottom: 5px;
      width: fit-content;
      margin-right: 5px;
      font-size: 14px;

    }
  }
</style>
